import React from "react"
import { Link } from "gatsby"

import Logo from "../../../assets/white_logo.svg"
import Linkedin from "../../../assets/linkedin.svg"
import Twitter from "../../../assets/twitter.svg"
import FooterLink from "../../data/footer_link.json"

const Footer = () => (
  <footer className="bg-brand-green">
    <div className="container-lg py-4 md:py-8">
      <div className="grid grid-cols-7">
        <div className="flex col-span-7 lg:col-span-2 md:max-w-xs">
          <Link to="/">
            <Logo className="h-10 -ml-4 mr-1 mt-1" alt="Arch Up" />
          </Link>
          <div>
            <p className="text-white mb-3">
              Career mentoring in Finance, from someone who’s
              been there.
            </p>
            <div className="flex items-center">
              <a
                href="https://www.linkedin.com/company/archup/"
                target="_blank"
                rel="noreferrer"
                aria-label="Linkedin"
              >
                <Linkedin className="fill-current text-white hover:text-brand-blue h-5 w-5 mr-3" />
              </a>
              <a
                href="https://twitter.com/ArchUp_UK"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <Twitter className="fill-current text-white hover:text-brand-blue h-6 w-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="flex col-span-7 lg:col-span-5 lg:pl-4 text-white w-full  mt-5 lg:mt-0">
          <div className="grid grid-cols-4 md:grid-cols-5 gap-6 w-full">
            {Object.keys(FooterLink).map(key => (
              <div
                className={`${
                  key === "More_Info"
                    ? "col-span-4 md:col-span-3"
                    : "col-span-2 md:col-span-1"
                }`}
              >
                <h1 className="font-bold mb-3 ">{key.replace("_", " ")}</h1>
                <div
                  className={`grid ${
                    key === "More_Info" ? "grid-cols-3" : "grid-cols-1"
                  }`}
                >
                  {FooterLink[key].map(({ label, link }) => (
                    <Link to={link} className="hover:text-brand-blue">
                      {label}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <a
          className=" text-white opacity-75 hover:opacity-100 text-sm"
          href="https://sld.codes"
        >
          This site was built by sld.codes.
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
