import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import Menu from "../../../assets/heroicons-0.4.1/optimized/outline/menu.svg"
import Close from "../../../assets/heroicons-0.4.1/optimized/outline/x-circle.svg"
import Logo from "../../../assets/full_colour_logo.svg"
import NavLinks from "../../data/nav_link.json"
import SmoothCollapse from "react-smooth-collapse"
import { useWindowSize } from "../../utils/useWindowResize"

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const size = useWindowSize();

  useEffect(() => {
    if(size.width > 768 && menuOpen ){
      setMenuOpen(false)
    }
  }, [size, menuOpen])

  return (
    <nav className="bg-white">
      <div className="container-lg py-4 flex overflow-x-hidden">
        <Link to="/" className="mr-8">
          <Logo className="icon-nav" alt="Arch Up" />
        </Link>
        <div className="items-center w-full text-brand-blue hidden md:flex">
          {NavLinks.map(({ title, link }) => (
            <Link to={link} className="mr-10">
              <h4 className="text-base font-medium hover:text-brand-green">
                {title}
              </h4>
            </Link>
          ))}
          <div className="ml-auto">
           
            <a href="https://app.archup.co.uk/login" className="btn" to="/mentors">Sign Up</a>
          </div>
        </div>
        <div className="flex md:hidden justify-end w-full">
          <button className="" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? (
              <Close className="text-gray-800 h-8 w-8" />
            ) : (
              <Menu className="text-gray-800 h-8 w-8" />
            )}
          </button>
        </div>
      </div>
      <SmoothCollapse expanded={menuOpen}>
        <div className="container-lg py-4 overflow-x-hidden">
          <div className="grid grid-cols-2 sm:grid-cols-3">
          {NavLinks.map(({ title, link }) => (
            <Link to={link} className="">
              <h4 className="text-lg text-brand-blue mb-3 font-medium hover:text-brand-green">
                {title}
              </h4>
            </Link>
          ))}
          <div className="col-span-2 grid grid-cols-2 gap-4 mt-3">
            <a href="https://app.archup.co.uk/login" className="btn text-center">Sign Up</a>
          </div>
          </div>
        </div>
      </SmoothCollapse>
    </nav>
  )
}

export default Nav
